import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle } from 'lucide-react';

// MISTable Component
const MISTable = ({ data, onActivate, onConnect, onCreateWorkflow }) => (
  <table className="min-w-full bg-gray-700 rounded-lg shadow-lg border-collapse">
    <thead>
      <tr className="bg-gray-600 text-gray-200">
        <th className="py-2 px-3 border border-gray-500 text-left">Service</th>
        <th className="py-2 px-3 border border-gray-500">Active</th>
        <th className="py-2 px-3 border border-gray-500">Credentials</th>
        <th className="py-2 px-3 border border-gray-500">Status</th>
        <th className="py-2 px-3 border border-gray-500">Actions</th>
      </tr>
    </thead>
    <tbody>
      {data.map((row, index) => (
        <tr key={index} className="hover:bg-gray-600">
          <td className="py-2 px-3 border border-gray-500">{row.service}</td>
          <td className="py-2 px-3 border border-gray-500 text-center">
            <input
              type="checkbox"
              checked={row.active}
              onChange={() => onActivate(index)}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
          </td>
          <td className="py-2 px-3 border border-gray-500">{row.credentials || '-'}</td>
          <td className="py-2 px-3 border border-gray-500 text-center">
            {row.status === 'connected' ? (
              <CheckCircle className="inline-block text-green-500" size={20} />
            ) : (
              <XCircle className="inline-block text-red-500" size={20} />
            )}
          </td>
          <td className="py-2 px-3 border border-gray-500">
            <button
              onClick={() => onConnect(index)}
              className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
              disabled={!row.active}
            >
              Connect
            </button>
            <button
              onClick={() => onCreateWorkflow(index)}
              className="bg-green-500 text-white px-2 py-1 rounded"
              disabled={!row.active || row.status !== 'connected'}
            >
              Create Workflow
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

// Modal for displaying LinkedIn connection data
const LinkedInDataModal = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-700">
        <h3 className="text-lg font-medium leading-6 text-white">LinkedIn Connection Summary</h3>
        <table className="min-w-full bg-gray-700 rounded-lg shadow-lg border-collapse mt-4">
          <thead>
            <tr className="bg-gray-600 text-gray-200">
              <th className="py-2 px-3 border border-gray-500 text-left">Assigned To</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Customer</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Sent</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Assigned</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index} className="hover:bg-gray-600">
                <td className="py-2 px-3 border border-gray-500">{row.assigned_to}</td>
                <td className="py-2 px-3 border border-gray-500">{row.customer}</td>
                <td className="py-2 px-3 border border-gray-500">{row.sent}</td>
                <td className="py-2 px-3 border border-gray-500">{row.assigned}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white p-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Modal for displaying analytics data including the missing fields
const AnalyticsDataModal = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-700">
        <h3 className="text-lg font-medium leading-6 text-white">Instantly Analytics Summary</h3>
        <table className="min-w-full bg-gray-700 rounded-lg shadow-lg border-collapse mt-4">
          <thead>
            <tr className="bg-gray-600 text-gray-200">
              <th className="py-2 px-3 border border-gray-500 text-left">Campaign Name</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Total Leads</th>
              <th className="py-2 px-3 border border-gray-500 text-left">In Progress</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Skipped</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Contacted</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Not Contacted</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Read</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Replied</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Bounced</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Unsubscribed</th>
              <th className="py-2 px-3 border border-gray-500 text-left">Completed</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index} className="hover:bg-gray-600">
                <td className="py-2 px-3 border border-gray-500">{row.campaign_name}</td>
                <td className="py-2 px-3 border border-gray-500">{row.total_leads}</td>
                <td className="py-2 px-3 border border-gray-500">{row.in_progress}</td>
                <td className="py-2 px-3 border border-gray-500">{row.skipped}</td>
                <td className="py-2 px-3 border border-gray-500">{row.contacted}</td>
                <td className="py-2 px-3 border border-gray-500">{row.not_yet_contacted}</td>
                <td className="py-2 px-3 border border-gray-500">{row.leads_who_read}</td>
                <td className="py-2 px-3 border border-gray-500">{row.leads_who_replied}</td>
                <td className="py-2 px-3 border border-gray-500">{row.bounced}</td>
                <td className="py-2 px-3 border border-gray-500">{row.unsubscribed}</td>
                <td className="py-2 px-3 border border-gray-500">{row.completed}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white p-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Modal for Client ID, Client Secret, and Gmail API Name Input
const ClientIdModal = ({ isOpen, onClose, onSubmit }) => {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [gmailApiName, setGmailApiName] = useState('');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-700">
        <h3 className="text-lg font-medium leading-6 text-white">Connect Gmail</h3>
        <div className="mt-2">
          <input
            type="text"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            placeholder="Client ID"
            className="mt-2 p-2 w-full bg-gray-600 text-white rounded"
          />
          <input
            type="text"
            value={clientSecret}
            onChange={(e) => setClientSecret(e.target.value)}
            placeholder="Client Secret"
            className="mt-2 p-2 w-full bg-gray-600 text-white rounded"
          />
          <input
            type="text"
            value={gmailApiName}
            onChange={(e) => setGmailApiName(e.target.value)}
            placeholder="Gmail API Name"
            className="mt-2 p-2 w-full bg-gray-600 text-white rounded"
          />
          <button
            onClick={() => onSubmit(clientId, clientSecret, gmailApiName)}
            className="mt-4 bg-blue-500 text-white p-2 rounded"
          >
            Generate Authorization URL
          </button>
        </div>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white p-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Modal for Access Token and Credential Generation
const AccessTokenModal = ({ isOpen, onClose, onSubmit }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-700">
        <h3 className="text-lg font-medium leading-6 text-white">Generate Access Token and Create Credentials</h3>
        <div className="mt-2">
          <button
            onClick={onSubmit}
            className="mt-4 bg-blue-500 text-white p-2 rounded"
          >
            Get Access Token and Create Credentials
          </button>
        </div>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white p-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Main App Component
const App = () => {
  const [isClientIdModalOpen, setIsClientIdModalOpen] = useState(false);
  const [isAccessTokenModalOpen, setIsAccessTokenModalOpen] = useState(false);
  const [isLinkedInModalOpen, setIsLinkedInModalOpen] = useState(false);
  const [isAnalyticsModalOpen, setIsAnalyticsModalOpen] = useState(false);
  const [linkedInData, setLinkedInData] = useState([]);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [tableData, setTableData] = useState([
    { service: 'Gmail', active: false, credentials: '', status: 'disconnected' },
    { service: 'Hubspot', active: false, credentials: '', status: 'disconnected' },
    { service: 'Github', active: false, credentials: '', status: 'disconnected' },
    { service: 'Google Analytics', active: false, credentials: '', status: 'disconnected' },
    { service: 'LinkedIn', active: false, credentials: '', status: 'disconnected' },
    { service: 'Canvas', active: false, credentials: '', status: 'disconnected' },
    { service: 'Google Search Console', active: false, credentials: '', status: 'disconnected' },
    { service: 'Instagram', active: false, credentials: '', status: 'disconnected' },
    { service: 'Website Search', active: false, credentials: '', status: 'disconnected' },
  ]);

  // Handle Activate Toggle
  const handleActivate = (index) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[index].active = !newData[index].active;
      return newData;
    });
  };

  // Fetch LinkedIn Connection Summary
  const handleLinkedInSummary = async () => {
    const webhookUrl = `https://lead.marketleap.in/webhook/linkedin`;

    try {
      const response = await fetch(webhookUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setLinkedInData(data);
        setIsLinkedInModalOpen(true);
      } else {
        alert('Failed to fetch LinkedIn summary');
      }
    } catch (error) {
      console.error('Error fetching LinkedIn summary:', error);
    }
  };

  // Fetch Analytics Data
  const handleInstantAnalytics = async () => {
    const webhookUrl = `https://lead.marketleap.in/webhook/instantly`;

    try {
      const response = await fetch(webhookUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setAnalyticsData(data);
        setIsAnalyticsModalOpen(true);
      } else {
        alert('Failed to fetch analytics data: Response not OK.');
        console.error('Response error:', response);
      }
    } catch (error) {
      alert('Error fetching analytics data. Please try again later.');
      console.error('Error fetching analytics data:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 text-gray-200 p-8">
      <nav className="bg-gray-700 p-4 mb-4 rounded">
        <h1 className="text-2xl font-bold inline-block">MIS Dashboard</h1>
        <button
          onClick={handleLinkedInSummary}
          className="bg-blue-500 text-white px-4 py-2 rounded ml-4"
        >
          Get LinkedIn Connection Summary
        </button>
        <button
          onClick={handleInstantAnalytics}
          className="bg-green-500 text-white px-4 py-2 rounded ml-4"
        >
          Instantly Analytics
        </button>
      </nav>

      <MISTable
        data={tableData}
        onActivate={handleActivate}
        onConnect={() => setIsClientIdModalOpen(true)}
        onCreateWorkflow={() => console.log('Create Workflow')}
      />

      {/* LinkedIn Data Modal */}
      <LinkedInDataModal
        isOpen={isLinkedInModalOpen}
        onClose={() => setIsLinkedInModalOpen(false)}
        data={linkedInData}
      />

      {/* Analytics Data Modal */}
      <AnalyticsDataModal
        isOpen={isAnalyticsModalOpen}
        onClose={() => setIsAnalyticsModalOpen(false)}
        data={analyticsData}
      />

      {/* First Modal: Get Client ID, Client Secret, and Gmail API Name */}
      <ClientIdModal
        isOpen={isClientIdModalOpen}
        onClose={() => setIsClientIdModalOpen(false)}
        onSubmit={() => console.log('ClientID Modal Submit')}
      />

      {/* Second Modal: Get Access Token and Create Credentials */}
      <AccessTokenModal
        isOpen={isAccessTokenModalOpen}
        onClose={() => setIsAccessTokenModalOpen(false)}
        onSubmit={() => console.log('AccessToken Modal Submit')}
      />
    </div>
  );
};

export default App;







